// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { E2EModule } from '@cosCoreModules/e2e/e2e.module';
import { IAppEnvironment } from './IAppEnvironment';

export const environment: IAppEnvironment = {
  label: 'staging',

  production: false,

  disableLogging: false,
  disableSentry: false,

  backendUrl: `https://api-core-stage.caronsale.de/api/v1`,

  idpUserPoolId: 'eu-central-1_B1ivk5SrS',
  idpUserPoolWebClientId: 'ui4t9urtmifaodlq6darn9sp0',
  idpCookieDomain: 'caronsale.de',
  idpCookieSecure: true,
  idpDisableCookieStorage: true,

  captchaSiteKey: `6Lcj65sUAAAAAFajuBznGFPGfNUyBxrAk7x2VAU8`,

  pickupCaptchaSiteKey: '6LdvxwwbAAAAAJgtGtSAYWaxRzto4J4A5VRNDs0E',

  logRocketIdentifier: null,

  pdfTronSecretKey: 'Castle Tech GmbH(caronsale.de):OEM:CarOnSale::B+:AMS(20210228):E5A504DD0437280AB360B13AC9A2537B60612FFDA776DD159DCF05634C70488054F2B6F5C7',

  googleAnalyticsId: 'G-F9X3R5T5YZ',

  googleTagManagerId: 'GTM-PCJ3QLKF',

  domain: 'caronsale.de',

  notificationCenterSocketUrl: 'https://cos-notification-srv-staging.herokuapp.com/',

  vehicleIntakeJotFormUrl: 'https://form.jotform.com/212912177856058',

  phraseConfig: {
    phraseProjectId: '0f536a58e5500bf2e87f3a0d5115d961',
    phraseAccessToken: 'b41bff11461079659104134c344bba376f226923177b5cb6cb78b9779429c421',
    phraseEndpointUrl: 'https://api.phrase.com/v2/projects/',
    locallyStoredDefaultLanguage: 'de',
    languageFallback: 'en',
    hiddenLanguagesPhraseVariableName: 'hiddenLanguages',
  },

  cargoUrl: 'https://cargo-stage.caronsale.de?utm_source=cos-marketplace&utm_medium=banner',
  cargoApiUrl: 'https://cargo-api-stage.caronsale.de',

  envModules: [E2EModule],

  growthbookApiUrl: 'https://cdn.growthbook.io',

  growthbookClientKey: 'sdk-09oSx4P9YteVyH3H',

  mixpanelProjectToken: '52a5904cd05279eaf8e61b263807b94c',

  salesforceUrl: 'https://caronsale--dev.sandbox.lightning.force.com',

  productFruitsWorkspaceCode: 'yyaKZrOYPUzpKXMz',

  zohoWorkspace: '20087941613',

  VATFactor: 1.19,

  billieStaticUrl: 'https://static-paella-sandbox.billie.io',

  amplitudeApiKey: '37dd2eee8e4f4f43883a4d1792f2a745',
  amplitudeExperimentDeploymentKey: 'client-xGNMXREr5uPBOfOk36Sczp6hZX6TL7B9',
  amplitudeSessionReplayEnabled: true,
  amplitudeProxyUrl: 'https://staging.caronsale.de/amplitude',

  useProdCloudinaryAccount: true,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
